import * as React from "react"
import { Link, graphql } from "gatsby"
import Logo from '../images/logo.png'
import Open from "../images/icon-menu.svg"
import "../sass/index.scss"

// markup
const NotFoundPage = ({ data: { allMarkdownRemark: { edges }, }, }) => {
  const Taller = edges[0].node.frontmatter;
  return (
    <>
    <header className="header">
    <div className="top">
      <div className="container">
        <button type="button" id="btnOpenMenu" className="btnIcon btnOpenNav">
          <img src={Open} alt="open" />
        </button>
        <nav className="navMain">
          <p className="tit">Menú</p>
          <ul>
            <li><a href={Taller.url} className="btn btnEmpresa btnNavAnchor_">Empresa</a></li>
            <li><a href={Taller.url} className="btn btnServicios btnNavAnchor_">Servicios</a></li>
            <li><a href={Taller.url} className="btn btnPromociones btnNavAnchor_">Promociones</a></li>
            <li><a href={Taller.url} className="btn btnContacto btnNavAnchor_">Contacto</a></li>
          </ul>
        </nav>
      </div>
    </div>
      
    <div className="bottom">
      <div className="container">
        <div className="flex">
          <img className="logo" src={Logo} alt="Bosch" />
          <p className={Taller.title.includes("<br/>")?"name two-lines-name":"name"} dangerouslySetInnerHTML={{ __html: Taller.title }}/>
        </div>
      </div>
    </div>
  </header>
    <main className="main">
    <section id="empresa" className={ "discover discover3" }>
      <div className="cooling">
        <p>Red de talleres Bosch Car Service</p>
      </div>
      <div className="content">
        <div className="container">
          <div className="cont">
            <h1 className="title">Página no encontrada</h1>
            <Link style={{ color: "#000", textDecoration: "underline" }} to="/">Volver</Link>.    
          </div>
        </div>
      </div>
    </section>       
    </main>
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {            
            title
            url
            customerId
            uuid            
            about            
            headerImage
          }
        }
      }
    }
  }
`